import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import shipping from './modules/shipping'
import dashboard from './modules/dashboard'
import vuexORM from "@/plugins/vuexORM";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
      vuexORM
  ],
  modules: {
    shipping,
    dashboard
  },

  state: {
    user: null
  },

  mutations,
  getters,
})
