import Vue from 'vue'
import axios from 'axios'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import {authTokenInterceptor} from '@/utils/jwt'
import User from "@/models/User";
import Country from "@/models/Country";
import Suburb from "@/models/Suburb";
import ShipperAccount from "@/models/ShipperAccount";
import Rate from "@/models/Rate";
import Shipment from "@/models/Shipment";
import Order from "@/models/Order";
import ShippingPackage from "@/models/ShippingPackage";
import AddressBookRecord from "@/models/AddressBookRecord";
import Invoice from "@/models/Invoice";
import Notification from "@/models/Notification";
import TrackingEvent from "@/models/TrackingEvent";
import {BACKEND_URL} from "../config";

const api = axios.create({
    baseURL: BACKEND_URL
});

api.interceptors.request.use(authTokenInterceptor)

Vue.prototype.$api = api;

VuexORM.use(VuexORMAxios, {
    axios: api,
    baseURL: BACKEND_URL
})

const database = new VuexORM.Database()

database.register(User)
database.register(Country)
database.register(Suburb)
database.register(ShipperAccount)
database.register(Order)
database.register(Rate)
database.register(Shipment)
database.register(ShippingPackage)
database.register(AddressBookRecord)
database.register(Invoice)
database.register(Notification)
database.register(TrackingEvent)

export default VuexORM.install(database)
