import Vue from 'vue'
import App from './App';
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import './plugins/chartist'
import './plugins/base'
import './plugins/vee-validate'
import './filters'

Vue.config.productionTip = false

if (process.env.ENABLE_SENTRY) {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN_KEY,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.FRONTEND_SERVER_NAME, /^\//],
      }),
    ],
    environment: process.env.SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
