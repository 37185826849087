import { Model } from '@vuex-orm/core'


class Shipment extends Model {
    static entity = 'shipments'

    static fields () {
        return {
            id: this.number(null),
            ship_from: this.attr(''),
            ship_to: this.attr(''),
            return_to: this.attr(''),
            date: this.attr(''),
            status: this.attr(''),
            tracking_number: this.attr(''),
            label: this.attr(''),
            delivery_instructions: this.attr(''),
            selected_rate: this.attr(''),
            insurance_for_customer: this.attr(''),
            customer_cost: this.attr(''),
            pickup_information: this.attr(''),
            customer_reference: this.attr(''),
            description: this.attr(''),
            reference: this.attr(''),
            parcels: this.attr(''),
            created: this.attr(''),
            modified: this.attr(''),
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        },
        actions: {
            createShipment(shipment) {
                return this.post('/api/v1/shipping/shipments/', shipment)
            },
            ship(shipmentId) {
                return this.post(`/api/v1/shipping/shipments/${shipmentId}/ship/`)
            }
        }
    }
}

export default Shipment
