import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import TokenService from '@/services/TokensService'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    component: () => import('@/views/landing/IndexLayout'),
    children: [
      {
        name: 'Home',
        path: '',
        component: () => import('@/views/landing/HomePage')
      },
    ]
  },
  {
    path: '/account/',
    component: () => import('@/views/landing/AccountFormLayout'),
    children: [
      {
        name: 'Register',
        path: 'register',
        meta: {
          anonOnly: true
        },
        component: () => import('@/views/landing/RegisterPage')
      },
      {
        name: 'Login',
        path: 'login',
        meta: {
          anonOnly: true,
        },
        component: () => import('@/views/landing/LoginPage')
      },
    ]
  },
  {
    path: '/dashboard/',
    component: () => import('@/views/dashboard/IndexLayout'),
    meta: {
      authOnly: true
    },
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/dashboard/Dashboard')
      },
      {
        name: 'Create shipment',
        path: 'quote',
        component: () => import('@/views/dashboard/pages/CreateShipment'),
      },
      {
        name: 'Shipments',
        path: 'shipments',
        component: () => import('@/views/dashboard/pages/Shipments'),
      },
      {
        name: 'Shipment',
        path: 'shipments/:id',
        component: () => import('@/views/dashboard/pages/ShipmentDetails'),
      },
      {
        name: 'Shipping packages',
        path: 'shipping_packages',
        component: () => import('@/views/dashboard/pages/ShippingPackageList')
      },
      {
        name: 'Invoices',
        path: 'invoices',
        component: () => import('@/views/dashboard/pages/Invoices'),
      },
      {
        name: 'Invoice',
        path: 'invoice/:id',
        component: () => import('@/views/dashboard/pages/InvoiceDetails'),
      },
      {
        name: 'Shipment tracking',
        path: 'tracking/:id',
        component: () => import('@/views/dashboard/pages/Tracking'),
      },
      {
        path: 'settings',
        component: () => import('@/views/dashboard/pages/Settings/Settings'),
        children: [
          {
            name: 'Profile',
            path: '',
            component: () => import('@/views/dashboard/pages/Settings/UserProfile')
          },
          {
            name: 'Shipping accounts',
            path: 'accounts',
            component: () => import('@/views/dashboard/pages/Settings/ShippingAccounts/ShippingAccounts')
          }
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes
})


const checkAuthorized = (to, from, next) => {
  if (to.matched.some(record => record.meta.authOnly && !TokenService.getAccessToken())) {
    next('/account/login')
    return
  }
  if (to.matched.some(record => record.meta.anonOnly && TokenService.getAccessToken())) {
    next('/dashboard')
    return
  }
  next()
}
router.beforeEach((to, from, next) => {
  checkAuthorized(to, from, next)
})

export default router
