import { Model } from '@vuex-orm/core'

class ShipperAccount extends Model {
    static entity = 'couriers'

    static fields () {
        return {
            id: this.attr(null),
            title: this.string(''),
            courier: this.string(''),
            created: this.string(''),
            owned: this.boolean(false),
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        }
    }
}

export default ShipperAccount
