import { Model } from '@vuex-orm/core'
import TokensService from '@/services/TokensService'

class User extends Model {
    static entity = 'users'

    static fields () {
        return {
            id: this.attr(null),
            username: this.attr(''),
            email: this.string(''),
            first_name: this.string(''),
            last_name: this.string(''),
            franchise: this.attr(''),
            franchise_admin: this.boolean(false)
        }
    }

    static apiConfig = {
        actions: {
            register ({email, username, password}) {
                return this.post(
                    '/auth/users/',
                    {email, username, password}
                );
            },
            async login ({email, password}) {
                const response = await this.post(
                    '/auth/jwt/create',
                    {email, password}
                )
                TokensService.setTokens(response.response.data)
                return response
            },
            logout() {
                TokensService.clear();
            }
        }
    }
}

export default User