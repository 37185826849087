import { Model } from '@vuex-orm/core'
import ShipperAccount from "@/models/ShipperAccount";

class Rate extends Model {
    static entity = 'rates'

    static fields () {
        return {
            id: this.number(null),
            shipper_account_id: this.number(''),
            service_code: this.string(''),
            service_name: this.string(''),
            transit_days: this.string(''),
            total_cost: this.attr(''),
            cost_currency: this.string(''),
            fees: this.attr([]),
            shipper_account: this.belongsTo(ShipperAccount, 'shipper_account_id', 'id')
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        },
        actions: {
            calculateRates ({shipFrom, shipTo, packages, termsOfTrade}) {
                return this.post(
                    '/api/v1/rates/rates/calculate_rates/',
                    {
                        ship_from: shipFrom,
                        ship_to: shipTo,
                        terms_of_trade: termsOfTrade,
                        packages
                    }
                );
            }
        }
    }
}

export default Rate
