import { Model } from '@vuex-orm/core'


class TrackingEvent extends Model {
    static entity = 'tracking_events'

    static fields () {
        return {
            order: this.attr({}),
            events: this.attr([])
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        }
    }
}

export default TrackingEvent
