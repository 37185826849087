import getters from "./getters";


export default {
    namespaced: true,

    state: {
        creatingShipment: {
            countryFrom: '',
            countryTo: '',
            cityFrom: '',
            cityTo: '',
            packages: [],
        }
    },

    mutations: {
        setCreatingShipment (state, payload) {
            state.creatingShipment = payload
        },
        updateCreatingShipment(state, payload) {
            state.creatingShipment = {...state.creatingShipment, ...payload}
        },
        setPackageItems(state, payload) {
            const { packageIndex, items } = payload;
            state.creatingShipment.packages[packageIndex].items = items;
        }
    },

    getters
}
