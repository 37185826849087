import { Model } from '@vuex-orm/core'


class Order extends Model {
    static entity = 'orders'

    static fields () {
        return {
            id: this.number(null),
            shipment: this.attr(''),
            ship_date: this.attr(''),
            status: this.attr(''),
            tracking_number: this.attr(''),
            label: this.attr(''),
            commercial_invoice: this.attr(''),
            selected_rate: this.attr(''),
            insurance_for_customer: this.attr(''),
            pickup_information: this.attr(''),
            customer_reference: this.attr(''),
            description: this.attr(''),
            reference: this.attr(''),
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        },
        actions: {
            createOrder(order) {
                return this.post('/api/v1/shipping/orders/', order)
            },
            ship(orderId) {
                return this.post(`/api/v1/shipping/orders/${orderId}/ship/`)
            }
        }
    }
}

export default Order
