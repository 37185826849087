import { Model } from '@vuex-orm/core'

class Country extends Model {
    static entity = 'countries'
    static primaryKey = 'code'

    static fields () {
        return {
            code: this.attr(''),
            title: this.attr('')
        }
    }
}

export default Country