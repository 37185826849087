
export function getAccessToken() {
  return localStorage.getItem('access_token');
}

export function getRefreshToken() {
  return localStorage.getItem('refresh_token');
}

export function setAccessToken(token) {
  localStorage.setItem('access_token', token)
}

export function setRefreshToken(token) {
  localStorage.setItem('refresh_token', token)
}

export function setTokens(tokens) {
  setAccessToken(tokens.access)
  if (tokens.refresh) {
    setRefreshToken(tokens.refresh)
  }
}

export function removeAccessToken() {
  localStorage.removeItem('access_token')
}

export function removeRefreshToken() {
  localStorage.removeItem('refresh_token')
}

export function clear() {
  removeAccessToken()
  removeRefreshToken()
}

export default {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setTokens,
  clear
}