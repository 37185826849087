import { Model } from '@vuex-orm/core'


class Notification extends Model {
    static entity = 'notifications'

    static fields () {
        return {
            id: this.number(null),
            text: this.attr(''),
            created: this.attr(''),
            read: this.attr('')
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        },
    }
}

export default Notification
