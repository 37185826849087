import { Model } from '@vuex-orm/core'
import Country from "@/models/Country";

class Suburb extends Model {
    static entity = 'suburbs'

    static fields () {
        return {
            id: this.attr(null),
            postal_code: this.attr(''),
            city: this.attr(''),
            country: this.attr(''),
            state: this.attr(''),
            countryObj: this.belongsTo(Country, 'country', 'code')
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        }
    }
}

export default Suburb
