import { Model } from '@vuex-orm/core'

class ShippingPackage extends Model {
    static entity = 'shippingPackages'

    static fields () {
        return {
            id: this.attr(''),
            title: this.attr(''),
            width: this.attr(''),
            height: this.attr(''),
            depth: this.attr(''),
            weight: this.attr(''),
            is_document: this.attr('')
        }
    }

    static apiConfig = {
        dataTransformer(response) {
            if (response.data.results) {
                return response.data.results
            }

            if (!Array.isArray(response.data)) {
                return [response.data]
            }

            return response.data
        }
    }
}

export default ShippingPackage
