import Vue from 'vue';
import moment from "moment";

Vue.filter('currency', function (value) {
    const currencyMapping = {
        'GBP': '£',
        'USD': '$',
        'EUR': '€'
    }
    return currencyMapping[value]
})


Vue.filter('date', function (value, format='LLL') {
    return moment(value).format(format)
})