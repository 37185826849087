import Vue from 'vue'
import {
    extend,
    ValidationObserver,
    ValidationProvider,
} from 'vee-validate'
import {
    email,
    max,
    min,
    numeric,
    required,
    double
} from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json';

extend('email', {
    validate: email.validate,
    message: messages.email
})

extend('decimal', {
    validate: (value) => /\d+(\.\d+)?/.test(value),
    message: 'Invalid value'
})
extend('max', {
    ...max,
    message: messages.max
})
extend('min', {
    ...min,
    message: messages.min
})
extend('numeric', {
    ...numeric,
    message: messages.numeric
})
extend('required', {
    validate: required.validate,
    message: messages.required,
    computesRequired: true
})
extend('double', {
    ...double,
    message: messages.double
})
extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
