import axios from 'axios'
import * as jwt from 'jsonwebtoken'
import TokensService from '@/services/TokensService'

// a little time before expiration to try refresh (seconds)
const EXPIRE_FUDGE = 10

// PRIVATE
const isTokenExpired = (token)=> {
    if (!token) return true
    const expire_in = getExpiresInFromJWT(token) - EXPIRE_FUDGE
    return !expire_in || expire_in < 0
}

// gets unix TS
const getTokenExpiresTimeStamp = (token) => {
    const decoded = jwt.decode(token)
    if (!decoded) return
    return decoded.exp
}

const getExpiresInFromJWT = (token) => {
    const exp = getTokenExpiresTimeStamp(token)
    if (exp) return exp - Date.now() / 1000

    return -1
}

const refreshToken = async (requestRefresh) => {
    const refreshToken = TokensService.getRefreshToken()
    if (!refreshToken) return Promise.reject('No refresh token available')

    try {
        // do refresh with default axios client (we don't want our interceptor applied for refresh)
        return await requestRefresh(refreshToken)
    } catch (err) {
        // failed to refresh... check error type
        if (err && err.response && (err.response.status === 401 || err.response.status === 422)) {
            // got invalid token response for sure, remove saved tokens because they're invalid
            TokensService.clear()
            return Promise.reject(`Got 401 on token refresh; Resetting auth token: ${err}`)
        } else {
            // some other error, probably network error
            return Promise.reject(`Failed to refresh auth token: ${err}`)
        }
    }
}

export const requestRefresh = (config) => async (refresh) => {
    const response = await axios.post('/auth/jwt/refresh/', {
        refresh
    }, config)
    TokensService.setTokens(response.data)
    return TokensService.getAccessToken()
}

export const authTokenInterceptor =  async (requestConfig) => {
    const header = 'Authorization',
          headerPrefix = 'JWT '

    // we need refresh token to do any authenticated requests
    if (!TokensService.getRefreshToken()) return requestConfig

    // do refresh if needed
    let accessToken
    try {
        accessToken = await refreshTokenIfNeeded(requestRefresh(requestConfig))
    } catch (err) {
        console.warn(err)
        return Promise.reject(
            `Unable to refresh access token for request: ${requestConfig} due to token refresh error: ${err}`
        )
    }

    // add token to headers
    if (accessToken) requestConfig.headers[header] = `${headerPrefix}${accessToken}`
    return requestConfig
}

export const refreshTokenIfNeeded = async (requestRefresh) => {
    // use access token (if we have it)
    let accessToken = TokensService.getAccessToken()

    // check if access token is expired
    if (!accessToken || isTokenExpired(accessToken)) {
        // do refresh
        accessToken = await refreshToken(requestRefresh)
    }

    return accessToken
}
