import { Model } from '@vuex-orm/core'

class AddressBookRecord extends Model {
    static entity = 'address_book_records'

    static fields () {
        return {
            id: this.attr(''),
            country: this.attr(''),
            contact_name: this.attr(''),
            phone: this.attr(''),
            email: this.attr(''),
            company_name: this.attr(''),
            street1: this.attr(''),
            street2: this.attr(''),
            street3: this.attr(''),
            city: this.attr(''),
            state: this.attr(''),
            suburb: this.attr('')
        }
    }
    static apiConfig = {
        dataKey: 'results'
    }
}

export default AddressBookRecord
