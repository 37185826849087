function getEnvVariable(name) {
    if (window.config && window.config[name]) {
        return window.config[name]
    }

    return process.env[name]
}

const BACKEND_MAPPING_RAW_VALUE = getEnvVariable('VUE_APP_BACKEND_MAPPING');
const BACKEND_MAPPING = (BACKEND_MAPPING_RAW_VALUE) ? JSON.parse(BACKEND_MAPPING_RAW_VALUE) : null;

let backend_url = getEnvVariable('VUE_APP_BACKEND_SERVER');
if (!backend_url) {
    backend_url = BACKEND_MAPPING[document.location.host]
}

export const BACKEND_URL = backend_url;
